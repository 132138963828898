import React from "react"
import { Link } from "gatsby"
import styled, { keyframes }  from "styled-components"
import style from "../styles/main"

const enlarge = keyframes`
  0%{
    left: 25%;
    width: 50%;
  }
  50% {
    left: 10%;
    width: 80%;
  }
  100%{
    left: 25%;
    width: 50%;
  }
`;

const Back = styled.div`
  position: fixed;
  float: left;
  display: block;
  height: 46px;
  width: 46px;
  top: 5px;
  z-index: 3;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    left: 5px;
    background-color: ${style.backgroundColor};
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    left: 88px;
    background-color: 'transparent';
  }
`;

const Cross = styled.div`
  display: block;
  position: absolute;
  height: 1px;
  left: 25%;
  width: 50%;
  top: 50%;
  background-color: ${style.firstColor};
  transform: rotate(${props => props.rotation}deg);
  //
  // ${Back}:hover & {
  //     animation: ${enlarge} 2s 1;
  // }
`;

class ProjectBack extends React.Component {
  render() {
    return(
      <Link to="/">
        <Back>
          <Cross rotation={45} />
          <Cross rotation={-45} />
        </Back>
      </Link>
    )
  }
}

export default ProjectBack
