import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"
import ProjectBack from "../components/projectback"
import ProjectHeader from "../components/projectheader"
import style from "../styles/main";
import PageMetadata from "../components/SEO"

const OuterRow = styled.div`
  background-color: ${props => props.color};
  margin-top: ${props => props.margin};
  margin-bottom: ${props => props.margin};
  margin-left: auto;
  margin-right: auto;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: calc(100% - 2*28px);
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    width: calc(100% - 222px);
    padding-bottom: 28px;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    flex-wrap: wrap;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    flex-wrap: nowrap;
  }
`;

const Column = styled.div`
  padding-top: 28px;
  flex-grow: ${props => props.grow};
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: 100%;
    padding-bottom: 28px;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    margin-top: 0px;
    margin-left: 5%;
    margin-right: 5%;
    left: 10%;
    width: 10%;
    flex-basis: 0;
  }
`;

const ImgWrapper = styled.div`
position: relative;
width: 100%;
margin-top: ${props => props.top ? props.top : '0px'};
`;

const VerticalLine = styled.div`
  border-left: medium solid #1D1D1B;
  margin-top: 10px;
  padding-left: 20px;
  font-size: 1.25em;
`;

const VerticalLineWithMargin = styled.div`
  border-left: medium solid #1D1D1B;
  padding-left: 20px;
  font-size: 1.25em;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    margin-top: -18px;
    margin-bottom: 28px;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    margin-top: 10px;
    margin-left: 5%;
  }
`;


function createHtml(text) {
  return {__html: text}
}


function formatImg(parent, name, ngimg) {
  if (parent.extension === 'gif' || ngimg) {
    return <img src={parent.publicURL} title={name} alt={name} width='100%'/>
  }
  const style = {width: '100%', objectFit: 'fit', height: 'auto'}
  return <Img imgStyle={style} fluid={parent.childImageSharp.fluid}  />
}

export default ({ data }) => {
  const post = data.yaml
  const rows = post.row.map((row) =>
    <OuterRow color={row.color}>
      <Row top_margin={row.top_margin} bottom_margin={row.bottom_margin}>
        {row.column.map((column) => {
            const grow = column.grow ? column.grow : 1
            return <Column grow={grow}>
              <ImgWrapper>
                {formatImg(column.file, column.name, column.ngimg)}
              </ImgWrapper>
              {column.second &&
                <ImgWrapper top={"56px"} >
                  {formatImg(column.second, column.name, column.ngimg)}
                </ImgWrapper>
              }
              { column.description &&
                <VerticalLine dangerouslySetInnerHTML={createHtml(column.description)}>
                </VerticalLine>
              }
            </Column>
        }
        )}
      </Row>
      {row.description &&
        <VerticalLineWithMargin dangerouslySetInnerHTML={createHtml(row.description)}>
        </VerticalLineWithMargin>
      }
    </OuterRow>
  )
  return (
    <>
      <PageMetadata />
      <ProjectBack/>
      <div>
        <ProjectHeader title={post.title} description={post.description} first_row={post.first_row.publicURL} desc_image={post.desc_image}></ProjectHeader>
        {rows}
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    yaml(fields: { slug: { eq: $slug } }) {
      description
      title
      desc_image {
        name
        file {
          id
          extension
          publicURL
        }
        width
      }
      first_row {
        id
        publicURL
      }
      row {
        description
        column {
          ngimg
          file {
            id
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
              }
            }
          }
          second {
            id
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
              }
            }
          }
          grow
          name
          description
        }
        color
        top_margin
        bottom_margin
      }
    }
  }
`
