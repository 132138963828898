import React from "react"
import styled, { keyframes }  from "styled-components"
import Media from 'react-media';
import style from "../styles/main"

const down = keyframes`
  0%{
      height:2vh
  }
  100%{
      height:8vh
  }
`;

const Scroll = styled.div`
  position:absolute;
  width:2px;
  background-color:#1D1D1B;
  height:2vh;
  top:90vh;
  left:calc(50% - 1px);
  animation: ${down} 1s infinite;
  transition:opacity 1s;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  margin: auto;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: calc(100% - 2*28px);
    flex-wrap: wrap;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    width: calc(100% - 222px);
    flex-wrap: nowrap;
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;

const MainImage = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 1;
  height:calc(100vh - 2 * 28px);
  background-position:center center;
  background-repeat:no-repeat;
  background-size: cover;
  background-image: url(${props => props.background});
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    margin-top: 28px;
    margin-bottom: 28px;
    width: 100%;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const Column = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 1;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: 100%;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const Description = styled.div`
  font-family: rewirFont;
  font-size: 1.25em;
  text-align: left;
  text-align-last: left;
  a {
    color: ${style.textColor};
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Title = styled.p`
  font-size: 1.25em;
  font-family: rewirFont;
  font-weight: bold;
`;

const Img = styled.img`
position: relative;
width: 100%;
`;

function createHtml(text) {
  return {__html: text}
}

function descImg(img) {
  if (img && img.file && img.width) {
    return(
      <div style={{ width: img.width }}>
        <Img src={img.file.publicURL}  alt={img.name}/>
      </div>
    )
  }
  if (img && img.file) {
    return(
      <div>
        <Img src={img.file.publicURL} alt={img.name}/>
      </div>
    )
  }
  return
}

class ProjectHeader extends React.Component {
  render() {
    return(
      <>
      <Media query={`(min-width: ${style.mediaMinWidth}px)`}>
        <Scroll/>
      </Media>
      <Row>
        <MainImage background={this.props.first_row} />
        <Column>
          <Title dangerouslySetInnerHTML={createHtml(this.props.title)} />
          <Description dangerouslySetInnerHTML={createHtml(this.props.description)}/>
          {descImg(this.props.desc_image)}
        </Column>
      </Row>
      </>
    )
  }
}

export default ProjectHeader
